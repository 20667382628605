import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSetRecoilState } from 'recoil';

import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';
import { siteSettingsAtom } from '../data/atoms';
import favicon from '../images/favicon.png';

function SEO({ seo: newSeo, defaultSettings, article }) {
  const { pathname } = useLocation();
  const setSettings = useSetRecoilState(siteSettingsAtom);

  useEffect(() => {
    setSettings(defaultSettings);
  }, [defaultSettings]);
  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    defaultImage,
    twitterHandle,
  } = defaultSettings;

  const seoImage = newSeo && newSeo.image
    ? `https://admin.clockworkbee.co.uk/assets/${newSeo.image.id}`
    : `https://admin.clockworkbee.co.uk/assets/${defaultImage.id}`;

  const seoData = {
    title: (newSeo && newSeo.title) || defaultTitle,
    description: (newSeo && newSeo.description) || defaultDescription,
    image: seoImage,
    url: `${siteUrl} || ${pathname}`,
  };

  return (
    <Helmet title={seoData.title} titleTemplate={titleTemplate}>
      <meta name="description" content={seoData.description} />
      <meta name="image" content={seoData.image} />

      {seoData.url && <meta property="og:url" content={seoData.url} />}
      {(article ? true : null) && <meta property="og:type" content="article" />}
      {seoData.title && <meta property="og:title" content={seoData.title} />}
      {seoData.description && (
        <meta property="og:description" content={seoData.description} />
      )}
      {seoData.image && <meta property="og:image" content={seoData.image} />}

      <meta name="twitter:card" content="summary_large_image" />

      {twitterHandle && <meta name="twitter:creator" content={twitterHandle} />}

      {seoData.title && <meta name="twiter:title" content={seoData.title} />}

      {seoData.description && (
        <meta name="twitter:description" content={seoData.description} />
      )}

      {seoData.image && <meta name="twitter:image" content={seoData.image} />}

      <link rel="icon" type="image/png" href={favicon} />
    </Helmet>
  );
}

export default SEO;

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
};

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
};
