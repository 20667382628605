import React from 'react';
import styled from 'styled-components';
import { useRecoilValue } from 'recoil';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { siteSettingsAtom } from '../data/atoms';

import { colors } from '../styles/colors';

import { logos } from '../data/logos';

const FooterElement = styled.footer`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-top: 10px;
  .logo {
    margin: 10px;
  }
  .logo a {
    color: ${colors.defaultColor};
  }
`;

function Footer() {
  const siteSettings = useRecoilValue(siteSettingsAtom);
  if (!siteSettings) {
    return null;
  }
  const twitterUrl = siteSettings.twitterHandle.slice(1);
  return (
    <FooterElement>
      <p className="logo">Find me on </p>
      <div className="logo grow">
        <a
          href={siteSettings.facebookUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={logos.facebook} size="1x" />
        </a>
      </div>
      <div className="logo grow">
        <a
          href={`https://www.twitter.com/${twitterUrl}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={logos.twitter} size="1x" />
        </a>
      </div>
      <div className="logo grow">
        <a
          href={siteSettings.instagramUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={logos.instagram} size="1x" />
        </a>
      </div>
      <div className="logo grow">
        <a
          href={`tel:${siteSettings.contactPhone}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={logos.phone} size="1x" />
        </a>
      </div>
      <div className="logo grow">
        <a
          href={`mailto:${siteSettings.contactEmail}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={logos.mail} size="1x" />
        </a>
      </div>
    </FooterElement>
  );
}

export default Footer;
